module.exports = [{
      plugin: require('/home/travis/build/smith/nlsmith.com/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('/home/travis/build/smith/nlsmith.com/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-1765938-1"},
    },{
      plugin: require('/home/travis/build/smith/nlsmith.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
